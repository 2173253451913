
import { Component, Vue, Emit } from 'vue-property-decorator';
import { Prop } from 'nuxt-property-decorator';

@Component
export default class blockTitleRow extends Vue {
  @Prop({ default: null }) public icon!: string;
  @Prop({ default: null }) public title!: string;
  @Prop({ default: null }) public subTitle!: string;
  @Prop({ default: false }) public showActionButton!: boolean;
  @Prop({ default: false }) public showSecondActionButton!: boolean;
  @Prop({ default: 'Error: Label not provided!' }) public buttonLabel!: string;
  @Prop({ default: 'mdi-plus-circle-outline' }) public buttonIcon!: string;
  @Prop({ default: 'Error: Label not provided!' })
  public secondButtonLabel!: string;

  @Prop({ default: 'mdi-plus-circle-outline' })
  public secondButtonIcon!: string;

  @Prop({ default: false }) public showRefreshButton!: boolean;
  @Prop({ default: '' }) public backLink!: string;
  @Prop({ default: null }) public checklist!: any;
  @Prop({ default: false }) public showChecklist!: boolean;
  public checkboxStatus: boolean = false;

  @Emit('actionButtonClick') actionButtonClicked() {}

  @Emit('secondActionButtonClick') secondActionButtonClicked() {}
  @Emit('refreshButtonClicked') refreshButtonClicked() {}

  @Emit('checkboxClicked') checkboxClicked() {
    return (this as any).checkboxStatus;
  }
}
